<!-- eslint-disable max-len -->
<template>
  <div class="hero-pattern min-h-screen flex flex-col justify-between items-center">
    <!-- <div v-if="!mobile" class="bg-primary-light"></div> -->
    <parcelex-business-header />
    <div class="flex-shrink-0 p-6 lg:p-0 w-full md:max-w-md">
      <div class="p-6 lg:p-9 lg:pb-8 bg-white rounded-2xl mb-6 shadow-lg">
        <form v-on:submit.prevent="handleLogin" class="login-wrapper" :class="{ mobile }">
          <header>
            <h1 class="font-bold text-xl mb-6">Área do lojista</h1>
          </header>

          <Input
            label="Email"
            inputIconName="input-email"
            class="mb-5"
            :class="[{ mobile }, { error: getMessage('email') }]"
            @input="formHandler('email', ...arguments)"
            :errorMessage="getMessage('email')"
            :disabled="loadingLogin"
          />

          <Input
            label="Senha"
            inputIconName="input-password"
            type="password"
            class="input-password"
            :class="[{ mobile }, { error: getMessage('password') }, 'mb-6']"
            @input="formHandler('password', ...arguments)"
            :errorMessage="getMessage('password')"
            :disabled="loadingLogin"
          />

          <Button class="button button-primary w-full mb-6" :class="{'button-loading': loadingLogin}">
            <img v-if="loadingLogin" src="brand-icon-loading.svg" class="w-5 animate-spin" alt="Ícone de Carregamento" />
            {{ loadingLogin ? "Validando..." : "Entrar" }}
          </Button>

          <div class="text-center">
            <router-link to="/recuperar-senha" class="hover:underline">Esqueci a senha</router-link>
          </div>
        </form>
      </div>
      <div class="text-center">
        <router-link to="/registrar" class="text-white hover:underline">
          Não tem uma conta? Crie grátis!
        </router-link>
      </div>
    </div>
    <footer class="text-center text-primary-500 pt-4 pb-6">
      &copy; Parcelex - {{ actualDate }}
    </footer>

    <div ref="agreeCookiePopup"
      :class="{ 'hidden': agreedCookies }"
      class="flex flex-col gap-4 lg:items-center lg:flex-row fixed z-50 left-6 right-6 bottom-6 max-w-xl mx-auto bg-black bg-opacity-90 text-white rounded-xl p-4"
    >
      <p>
        Utilizamos cookies e outras tecnologias para oferecer a você uma melhor experiência no nosso site.
        Acesse nossa <a class="text-primary-500 underline" target='_blank' :href="privacyPolicy">
          Política de Privacidade
        </a>
        para mais informações. Ao continuar navegando você estará de acordo com estas condições.
      </p>
      <div>
        <button class="button button-primary w-full lg:w-auto" @click="agreeCookie">
          Entendi
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.button-loading {
  @apply bg-gray-100 border-gray-100 text-gray-400 cursor-default;
}
.button-loading:hover {
  /* background-color: rgba(21, 66, 82, var(--tw-bg-opacity)); */
  @apply bg-gray-100;
}
</style>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import Input from '@/components/ui/AppInput.vue';
import Button from '@/components/ui/AppButton.vue';
import UtilMixin from '@/mixins/util';
import globals from '../../../globals';
import ParcelexBusinessHeader from '../../../components/common/ParcelexBusinessHeader.vue';

export default {
  name: 'login',
  metaInfo: {
    title: 'Para Negócios',
  },
  data: () => ({
    mobile: false,
    loadingLogin: false,
    formInputs: {
      email: {
        currentValue: undefined,
        isValid: false,
        validateFunction: 'isValidEmail',
        errorMessage: 'Email inválido',
        currentMessage: '',
      },
      password: {
        currentValue: undefined,
        isValid: false,
        validateFunction: 'isValidPassword',
        errorMessage: 'Senha inválida',
        currentMessage: '',
      },
    },
  }),
  mixins: [UtilMixin],
  components: { Input, Button, ParcelexBusinessHeader },
  computed: {
    ...mapState('auth', ['agreedCookies', 'step', 'token', 'username']),
    actualDate() {
      return new Date().getFullYear();
    },
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    privacyPolicy() {
      return `${globals().institucionalUrl}politica-de-privacidade`;
    },
  },
  methods: {
    ...mapActions('auth', ['login', 'register']),
    ...mapMutations('auth', ['setAgreedCookies']),
    async handleLogin() {
      this.loadingLogin = true;
      if (!this.isValidForm) {
        this.errorMessageHandler();
      } else {
        await this.login({
          username: this.formInputs.email.currentValue,
          password: this.formInputs.password.currentValue,
        }).then(() => {
          this.loadingLogin = false;
        });
      }
    },
    formHandler(type, value) {
      if (!value) return;

      const { validateFunction } = this.formInputs[type];
      this.formInputs[type].isValid = this[validateFunction](value);
      this.formInputs[type].currentValue = value;

      if (this.formInputs[type].currentMessage !== '') {
        this.formInputs[type].currentMessage = '';
      }
    },
    errorMessageHandler() {
      Object.keys(this.formInputs).forEach((key) => {
        if (!this.formInputs[key].isValid) {
          this.formInputs[key].currentMessage = this.formInputs[key].errorMessage;
        }
      });
    },
    getMessage(type) {
      return this.formInputs[type].currentMessage;
    },
    isValidPassword(password) {
      return password && password.length > 3;
    },
    goToRegister() {
      this.$router.push('/signup');
    },
    agreeCookie() {
      this.setAgreedCookies(true);
    },
  },
  mounted() {
    this.mobile = this.isMobile();
  },
};
</script>
